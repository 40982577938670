import React from "react"
import { Link, Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

export default function Home() {
  return (
    <div className="flex flex-col items-center justify-center text-center h-full">
      <p className="text-sm uppercase max-w-md mb-8">
        <Trans i18nKey="home.header">
          Arcanode is an <strong>Italian Web3 company</strong> developing
          real-time 3D applications, offering highly customizable and innovative
          solutions for individuals and businesses.
        </Trans>
      </p>
      <Link
        to="/contact"
        className="border hover:bg-gray-200 hover:text-gray-700 duration-500 text-white font-bold uppercase text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        <Trans i18nKey="home.button">Request a Demo</Trans>
      </Link>
    </div>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
